export const EBUYCLUB = 0;
export const AIR_FRANCE = 53;
export const CASINO_MAX = 59;
export const MAX_ARKEA = 67;
export const CAN_B = 76;
export const CYRANA_BE_FR = 77;
export const CYRANA_BE_NL = 80;
export const ORCHESTRA = 87;
export const HUBSIDE_BE_FR = 91;
export const HUBSIDE_BE_NL = 95;
export const SPORTYNEO = 101;
export const PROXIGITAL = 102;
export const RCT = 106;
export const LA_BANQUE_POSTALE = 110;
export const FRANCE_VERIF = 112;
export const MAMA_BEARS = 122;
export const RESILIENCE = 123;
export const REDUC_PLUS = 125;
export const BFM = 127;
export const LA_BOUTIQUE_E_BONS = 149;

export const MULTILINGUAL_APPLICATIONS = [CYRANA_BE_FR, CYRANA_BE_NL, HUBSIDE_BE_FR, HUBSIDE_BE_NL];

export const ALL_APPLICATIONS_ID = -1;
export const ALL_MB_ID = -2;
export const MULTI_APPLICATION_IDS = [ALL_APPLICATIONS_ID, ALL_MB_ID];

export const EBUYCLUB_URL = process.env.REACT_APP_EBUYCLUB_URL;

export const MB_UPDATED_RESOURCE_ORIGIN = 69;

// Holdings
export const SFAM = 2;
